<!-- 롤링 모달 -->
<div class="modal-content">
    <div class="modal-body bg-stand relative rounded">
        <!-- Modal Header -->
        <div class="relative flex items-center justify-between p-4 bg-modaldark">
            <p class="text-tit font-extrabold text-lg">매일매일 롤링왕</p>
            <button class="basic-hover ml-8" data-tw-dismiss="modal">
                <svg class="w-4 h-4 close_btn cursor-pointer hover:-rotate-90 duration-500 transition-all">
                    <use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Close"></use>
                </svg>
            </button>
        </div>

        <!-- Modal Body -->
        <div class="p-4 py-6 max-h-[660px] overflow-y-auto scrollbar">
            <!-- Event Date -->
            <div class="text-base font-bold mb-2">2024. 10. 10. ~ 2024. 12. 31.</div>

            <div class="text-sm space-y-3" style="color: #333;">
                <p style="color: #16a085;">1. 이벤트의 상금 규모는 베팅액에 따라 크게 달라지며, 더 많은 플레이어가 베팅할수록 그 금액도 커집니다. 현재 상금액은 콘테스트 페이지에 표시됩니다.</p>

                <p style="color: #16a085;">2. 1~10위까지의 베팅 플레이어는 상금액을 분할합니다.</p>

                <p style="color: #16a085;">3. 이 대회는 모든 베팅을 지원합니다.</p>

                <p style="color: #16a085;">4. 상금은 매일 23:59에 종료되며, 익일 공지사항 페이지를 통해 발표 및 자동 지급됩니다.</p>                

                <p style="color: #16a085;">5. KBC GAME은 해당 이벤트의 어느 단계에서나 규칙을 위반한 플레이어를 제외할 권리를 보유합니다.</p>
                
                <p style="color: #16a085;">6. KBC GAME은 단독 재량에 따라 사전 통지 없이 규칙과 조건을 변경할 권리를 보유합니다.</p>
            
                <p class="font-bold text-lg mt-4 text-center" style="color: #e74c3c;">🌟행운이 함께하기를 바랍니다.재미있게 즐겨 보세요!🌟</p>
            </div>
            <!-- Prize Calculation -->
            <div class="text-base font-bold mt-8">상금 계산 공식</div>
            <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">1위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">50% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">2위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">25% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">3위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">12% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">4위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">6% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">5위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">3% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">6위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">1.5% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">7위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">0.9% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">8위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">0.7% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">9위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">0.5% 콘테스트 상금 풀 중 데일리</td>
                </tr>
                <tr>
                    <td style="border: 1px solid #ccc; padding: 8px;">10위</td>
                    <td style="border: 1px solid #ccc; padding: 8px;">0.4% 콘테스트 상금 풀 중 데일리</td>
                </tr>
            </table>
        </div>
    </div>
</div>
